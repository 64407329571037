var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex"},[_c('b-sidebar',{staticClass:"sidebar-options-list",attrs:{"id":"options-sidebar-right","right":"","bg-variant":"white","width":_vm.device === 'desktop' ? '407px' : _vm.device === 'tablet' ? '50vw' : '100vw',"no-close-on-esc":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{class:[
          'header-sidebar d-flex',
          _vm.device === 'mobile' ? 'justify-content-start' : 'justify-content-between'
        ]},[(_vm.device === 'mobile')?_c('span',{staticClass:"arrow-left-icon",on:{"click":function($event){return _vm.closeModal()}}},[_c('ArrowLeft')],1):_vm._e(),_c('span',{staticClass:"sidebar-title"},[_vm._v(" "+_vm._s(!_vm.isCheckbox ? _vm.$t('DataFieldsPage.ListOptions') : _vm.$t('DataFieldsPage.SelectionOptions'))+" "),_c('span',{attrs:{"id":"info-option"}},[_c('InfoIcon')],1)]),_c('Tooltip',{attrs:{"target":"info-option","text":!_vm.isCheckbox
              ? _vm.$t('DataFieldsPage.InfoOptionList')
              : _vm.$t('DataFieldsPage.SelectionToolTipText'),"placement":"top"}})],1)]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"separator"}),_c('b-col',{staticClass:"footer-sidebar",attrs:{"md":"12"}},[_c('div',{class:['d-flex', 'buttons']},[_c('b-button',{class:['btn-cancel', 'btn-footer'],on:{"click":_vm.onCancel}},[_vm._v(" "+_vm._s(_vm.$t('RefuseStatus.Cancel'))+" ")]),_c('b-button',{staticClass:"btn-save btn-footer",on:{"click":function($event){return _vm.onSave()}}},[_vm._v(" "+_vm._s(_vm.$t('RefuseStatus.Save'))+" ")])],1)])]},proxy:true}]),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[[_c('div',[_vm._l((_vm.optionsList),function(item,index){return _c('div',{key:item.text + '-' + index},[_c('b-col',{staticClass:"item-input",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"","label-for":item.text,"invalid-feedback":(_vm.isToValidate
                  ? _vm.optionsList.filter(function (e) { return e.text && e.text === item.text; }).length > 1
                  : false) || _vm.flagEqualOptionName
                  ? _vm.$t('DataFieldsPage.EqualOptionName')
                  : _vm.$t('RequiredField')}},[_c('b-form-input',{attrs:{"id":item.text,"value":item.text,"lazy-formatter":"","formatter":function (e) {
                    var hasEqualNames =
                      _vm.optionsList.filter(function (e) { return e.text && e.text === item.text; }).length > 1;
                    var hasEmptyOptions = _vm.optionsList.find(function (e) { return e.text === ''; });
                    if (e.trim() !== '' && !hasEqualNames && !hasEmptyOptions) {
                      _vm.isToValidate = false;
                    }

                    return e;
                  },"state":_vm.verifyState(item),"placeholder":_vm.$t('DataFieldsPage.OptionName')},on:{"change":function (e) { return (item.text = e); }}})],1),_c('div',{staticClass:"delete-option",on:{"click":function($event){return _vm.removeItem(index)}}},[_c('TrashIcon')],1)],1)],1)}),_c('b-button',{staticClass:"btn-add-option",on:{"click":function($event){return _vm.addNewOption()}}},[_c('AddCircleIcon',{staticClass:"add-icon"}),_vm._v(" "+_vm._s(_vm.$t('DataFieldsPage.NewOption'))+" ")],1)],2)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }